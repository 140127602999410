<template lang="pug">
filter-template.date-filter(
  show-sorting
  :show-warnings="showWarnings"
  :sorting-order="filters.sorting.find(e => e.title === 'dateApplication')?.order"
  @save="applyFilters"
  @cancel="cancel"
  @set-order="setOrder"
  @reset="cleanFilter"
)
  template(#header) Дата подачи заявок
  template(#content)
    date-filter-card(
      date-type="dateApplication"
      :date-value="filters.dateApplication"
      :default-range="isSearch ? dateApplicationLimit : defaultDateFilter"
      @update:date="filters.dateApplication = $event.value"
    )
</template>

<script lang="ts">
import type { PropType } from "vue";
import type { PreFiltrationFormI } from "@/utils/getters/defaultFilters";

import { computed, defineComponent, toRefs } from "vue";
import { setSorting } from "@/utils/filters/sorting";
import { getDefaultPreFiltrationForm } from "@/utils/getters/defaultFilters";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import { useRoute } from "vue-router";
import isEqual from "lodash/isEqual";

import DateFilterCard from "@/components/searchForm/elements/DateFilterCard.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import useSearchStore from "@/stores/search/useSearchStore";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "DateApplicationFilter",
  components: {
    DateFilterCard,
    FilterTemplate,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object as PropType<PreFiltrationFormI>,
      default: getDefaultPreFiltrationForm(),
    },
  },
  emits: [
    'close',
    'apply',
    'update:modelValue',
  ],
  setup(props, { emit }) {

    const route = useRoute();
    const { modelValue, visible } = toRefs(props)

    const defaultDateFilter = getDefaultPreFiltrationForm().dateApplication

    const searchStore = useSearchStore();
    const isSearch = computed(() => searchStore.searchPageMode === 'fullSearchMode' && route.name === Tabs.Search)
    const dateApplicationLimit = computed(() => searchStore.searchFormDataFilters.dateApplication)

    const showWarnings = computed<boolean>(() =>
      isSearch.value &&
      !isEqual(dateApplicationLimit.value, defaultDateFilter)
    )

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormI>(modelValue, visible, ['dateApplication'], emit, route.name)

    function setOrder(mode: 'asc'|'desc'|'') {
      filters.value.sorting = setSorting('dateApplication', mode, filters.value.sorting);
    }

    return {
      isSearch,
      filters,
      showWarnings,
      dateApplicationLimit,
      defaultDateFilter,
      cancel,
      applyFilters,
      cleanFilter,
      setOrder,
    };
  },
});
</script>

<style scoped lang="scss">
.date-filter {
  width: 456px;
}
</style>
