<template lang="pug">
filter-template.cost-filter(
  :show-sorting="true"
  :sorting-order="filters.sorting.find(e => e.title === 'costContract')?.order"
  @save="applyFilters"
  @cancel="cancel"
  @set-order="setOrder"
  @reset="cleanFilter"
)
  template(#header) Цена контракта
  template(#content)
    cost-range.cost_range(
      label="Цена контракта"
      v-model:from="filters.costContract.costFrom"
      v-model:to="filters.costContract.costTo"
    )
    currency-selector(v-model="filters.costContract.currency")
</template>

<script lang="ts">
import { defineComponent, toRefs, } from "vue";
import { getDefaultPreFiltrationFormContract } from "@/utils/getters/defaultFilters";
import { setSorting } from "@/utils/filters/sorting";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";

import CostRange from "@/components/ui/form/input/CostRange.vue"
import CurrencySelector from "@/components/selector/CurrencySelector.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";

import type { PropType } from "vue";
import type { PreFiltrationFormContractI } from "@/utils/getters/defaultFilters";

export default defineComponent({
  name: "CostContractFilter",
  components: {
    CostRange,
    CurrencySelector,
    FilterTemplate,
  },
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormContractI>,
      default: getDefaultPreFiltrationFormContract(),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'close',
    'apply',
  ],
  setup(props, { emit }) {

    const { modelValue, visible } = toRefs(props)

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormContractI>(modelValue, visible, ['costContract'], emit, 'contracts')

    function setOrder(mode: 'asc'|'desc'|'') {
      filters.value.sorting = setSorting('costContract', mode, filters.value.sorting);
    }

    return {
      filters,
      cleanFilter,
      applyFilters,
      cancel,
      setOrder,
    };
  },
});
</script>

<style scoped lang="scss">
.cost-filter {
  width: 420px;

  .cost_range {
    display: flex;
  }
}
</style>
