<template lang="pug">
filter-template.cost-filter(
  show-sorting
  :show-warnings="showWarnings"
  :sorting-order="filters.sorting.find(e => e.title === 'cost')?.order"
  @save="applyFilters"
  @cancel="cancel"
  @set-order="setOrder"
  @reset="cleanFilter"
)
  template(#header)
    .cost-title
      | Цена
      old-warning(:label="hint")
  template(#content)
    price-and-payment-terms(
      v-model:cost="filters.cost"
      v-model:costApp="filters.costApp"
      :costLimit="isSearch ? costLimit : defaultCost"
      :costAppLimit="isSearch ? costAppLimit : defaultCost"
    )
</template>

<script lang="ts">
import type { PropType } from "vue";
import type { PreFiltrationFormI } from "@/utils/getters/defaultFilters";

import { computed, defineComponent, toRefs } from "vue";
import { getDefaultPreFiltrationForm } from "@/utils/getters/defaultFilters";
import { setSorting } from "@/utils/filters/sorting";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import { useRoute } from "vue-router";

import PriceAndPaymentTerms from "@/components/searchForm/elements/PriceAndPaymentTerms.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import OldWarning from "@/components/ui/tooltip/OldWarning.vue";
import useSearchStore from "@/stores/search/useSearchStore";
import isEqual from "lodash/isEqual";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "CostFilter",
  components: {
    OldWarning,
    FilterTemplate,
    PriceAndPaymentTerms,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object as PropType<PreFiltrationFormI>,
      default: getDefaultPreFiltrationForm(),
    },
  },
  emits: [
    'close',
    'apply',
    'update:modelValue',
  ],
  setup(props, { emit }) {

    const route = useRoute();
    const { modelValue, visible } = toRefs(props)

    const defaultCost = getDefaultPreFiltrationForm().cost

    const hint = "Фильтр по суммам будет учитывать указанную валюту." +
      " Если указанная валюта и валюта закупки не совпадают, то фильтрация будет происходить по текущему курсу" +
      " Центрального банка РФ, действительную в момент запроса."

    const searchStore = useSearchStore();
    const isSearch = computed(() => searchStore.searchPageMode === 'fullSearchMode' && route.name === Tabs.Search)
    const costLimit = computed(() => searchStore.searchFormDataFilters.cost)
    const costAppLimit = computed(() => searchStore.searchFormDataFilters.costApp)

    const showWarnings = computed<boolean>(() =>
      isSearch.value
      && (!isEqual(costLimit.value, defaultCost) || !isEqual(costAppLimit.value, defaultCost))
    )

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent(modelValue, visible, ['cost', 'costApp'], emit, route.name)

    function setOrder(mode: 'asc'|'desc'|'') {
      filters.value.sorting = setSorting('cost', mode, filters.value.sorting);
    }

    return {
      hint,
      filters,
      showWarnings,
      cancel,
      applyFilters,
      cleanFilter,
      isSearch,
      defaultCost,
      setOrder,
      costLimit,
      costAppLimit,
    };

  },
});
</script>

<style scoped lang="scss">
.cost-filter {
  width: 480px;
}
.cost-title {
  display: flex;
  gap: 4px;
  align-items: center;
}
</style>
