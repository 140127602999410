<template lang="pug">
filter-template.date-filter(
  show-sorting
  :sorting-order="filters.sorting.find(e => e.title === 'dateAuction')?.order"
  @save="applyFilters"
  @cancel="cancel"
  @set-order="setOrder"
  @reset="cleanFilter"
)
  template(#header) Дата торгов
  template(#content)
    date-filter-card(
      date-type="dateAuction"
      :date-value="filters.dateAuction"
      @update:date="filters.dateAuction = $event.value"
    )
</template>

<script lang="ts">
import type { PropType } from "vue";
import type { PreFiltrationFormI } from "@/utils/getters/defaultFilters";

import { defineComponent, toRefs } from "vue";
import { getDefaultPreFiltrationForm } from "@/utils/getters/defaultFilters";
import { setSorting } from "@/utils/filters/sorting";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import { useRoute } from "vue-router";

import DateFilterCard from "@/components/searchForm/elements/DateFilterCard.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";

export default defineComponent({
  name: "DateAuctionFilter",
  components: {
    DateFilterCard,
    FilterTemplate,
  },
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormI>,
      default: getDefaultPreFiltrationForm(),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'close',
    'apply',
  ],
  setup(props, { emit }) {

    const route = useRoute();
    const { modelValue, visible } = toRefs(props)

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormI>(modelValue, visible, ['dateAuction'], emit, route.name)

    function setOrder(mode: 'asc'|'desc'|'') {
      filters.value.sorting = setSorting('dateAuction', mode, filters.value.sorting);
    }

    return {
      filters,
      cancel,
      applyFilters,
      cleanFilter,
      setOrder,
    };
  }
});
</script>

<style scoped lang="scss">
.date-filter {
  width: 456px;
}
</style>
