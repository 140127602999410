<template lang="pug">
filter-template.lot-tag-filter(
  :show-sorting="false"
  @save="applyFilters"
  @cancel="cancel"
  @reset="cleanFilter"
)
  template(#header) Теги
  template(#content)
    .block
      ui-checkbox(v-if="tags.length > 1" as-group v-model="selectAllGetter" label="Выбрать все" :indeterminate="isIndeterminate && !selectAllGetter" id="selectAllTagsFilter")
      checkbox-group.filter(v-model="filters.tagIds" :options="tags" id="user-tag" :class="tags.length > 3 && 'grid-view'")
        template(#label="{ option }")
          lot-tag(:tag="option" size="mini")
</template>

<script lang="ts">
import { computed, type PropType } from 'vue'
import type { PreFiltrationFormI } from "@/utils/getters/defaultFilters";

import { defineComponent, toRefs } from 'vue'
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import UiCheckbox from "~/components/ui/checkbox/UiCheckbox.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import CheckboxGroup from "~/components/ui/checkbox/CheckboxGroup.vue";
import LotTag from "~/components/tags/LotTag.vue";
import useSearchStore from "~/stores/search/useSearchStore";

export default defineComponent({
  name: "LotTagsFilter",
  components: {
    UiCheckbox,
    LotTag,
    CheckboxGroup,
    FilterTemplate,
  },
  emits: [
    'close',
    'apply',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormI>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {

    const searchStore = useSearchStore();
    const { tags } = storeToRefs(searchStore);

    const { modelValue } = toRefs(props)

    const route = useRoute();

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormI>(modelValue, false, ['tagIds'], emit, route.name)

    const allIds = computed<number[]>(() => tags.value.map(e => e.id))

    const selectAllGetter = computed({
      get: () => allIds.value.every(id => filters.value?.tagIds?.includes(id)),
      set(value: boolean) {
        filters.value.tagIds = value ? allIds.value : []
      }
    })

    const isIndeterminate = computed(() => allIds.value.some(id => filters.value?.tagIds.includes(id)))

    return {
      tags,
      filters,
      cancel,
      cleanFilter,
      applyFilters,
      selectAllGetter,
      isIndeterminate,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.lot-tag-filter {
  min-width: 340px;
}

.block {
  display: flex;
  flex-flow: column;
}

.filter {
  display: flex;
  flex-flow: column;
}

.filter.grid-view {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
  column-gap: 16px;
  max-height: 320px;
  overflow: auto;
  padding-right: 4px;
}
</style>
