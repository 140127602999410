<template lang="pug">
filter-template(
  show-sorting
  :sorting-order="filters.sorting.find(e => e.title === 'status')?.order"
  @save="applyFilters"
  @cancel="cancel"
  @set-order="setOrder"
  @reset="cleanFilter"
)
  template(#header) Статус заявки
  template(#content)
    .app-statuses
      ui-checkbox.fit-content(as-group v-model="selectAllGetter" id="selectAllAppStatuses" label="Выбрать все" :indeterminate="isIndeterminate && !selectAllGetter")
      .status-filter__content
        ui-checkbox(v-if="showWithoutStatus" v-model="filters.withoutStatus" label="Без статуса" id="withoutStatus")
        checkbox-group(v-model="filters.appStatusIds" :options="appStatuses" id="appStatus")
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from "vue";
import { setSorting } from "@/utils/filters/sorting";
import { getDefaultPreFiltrationForm } from "@/utils/getters/defaultFilters";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import useManualsStore from "@/stores/manuals/useManualsStore";

import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import CheckboxGroup from "@/components/ui/checkbox/CheckboxGroup.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";

import type { PropType } from "vue";
import type { PreFiltrationFormI } from "@/utils/getters/defaultFilters";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "StatusFilter",
  components: {
    UiCheckbox,
    CheckboxGroup,
    FilterTemplate,
  },
  emits: [
    'update:modelValue',
    'close',
    'apply',
  ],
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormI>,
      default: getDefaultPreFiltrationForm(),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    const manualsStore = useManualsStore()
    const { appStatuses } = storeToRefs(manualsStore)

    const route = useRoute();
    const { modelValue, visible } = toRefs(props)

    const showWithoutStatus = route.name === Tabs.Search;

    const allIds = appStatuses.value.map(e => e.id)

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormI>(modelValue, visible, ['appStatusIds', 'withoutStatus'], emit, route.name)

    function setOrder(mode: 'asc'|'desc'|'') {
      filters.value.sorting = setSorting('status', mode, filters.value.sorting);
    }

    const selectAllGetter = computed({
      get() {
        if (showWithoutStatus) return allIds.every(id => filters.value?.appStatusIds?.includes(id)) && filters.value.withoutStatus
        else return allIds.every(id => filters.value?.appStatusIds?.includes(id))
      },
      set(value: boolean) {
        filters.value.appStatusIds = value ? allIds : []
        if (showWithoutStatus) filters.value.withoutStatus = value
      }
    })

    const isIndeterminate = computed(() => {
      if (showWithoutStatus) return allIds.some(id => filters.value?.appStatusIds.includes(id)) || filters.value.withoutStatus
      else return allIds.some(id => filters.value?.appStatusIds.includes(id))
    })

    return {
      showWithoutStatus,
      selectAllGetter,
      isIndeterminate,
      filters,
      appStatuses,
      cleanFilter,
      cancel,
      applyFilters,
      setOrder,
    };
  },
});
</script>

<style scoped lang="scss">
.status-filter__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: column;
  width: 470px;
}

.app-statuses {
  display: flex;
  flex-flow: column;
}

.fit-content {
  width: fit-content;
}

:deep(.ui-checkbox) {
  display: flex;
  justify-content: center;
  height: 32px;
}

:deep(.checkbox-group) {
  display: contents;
}
</style>
