<template lang="pug">
filter-template.guarantee-filter(
  @save="applyFilters"
  @cancel="cancel"
  @reset="cleanFilter"
)
  template(#header) Размер обеспечений
  template(#content)
    .cost-wrapper
      cost-range.prepayment_cost(
        label="Обеспечение исполнения контракта"
        v-model:from="filters.costContractGuarantee.costFrom"
        v-model:to="filters.costContractGuarantee.costTo"
      )
      ui-checkbox(
        id="uncertain_costContractGuarantee"
        v-model="filters.costContractGuarantee.uncertainCost"
        label="Искать с неопределенной ценой"
      )
    .cost-wrapper
      cost-range.prepayment_cost(
        label="Размер гарантийных обязательств"
        v-model:from="filters.costPostContractGuarantee.costFrom"
        v-model:to="filters.costPostContractGuarantee.costTo"
      )
      ui-checkbox(
        id="uncertain_costPostContractGuarantee"
        v-model="filters.costPostContractGuarantee.uncertainCost"
        label="Искать с неопределенной ценой"
      )
    currency-selector(
      v-model="filters.costContractGuarantee.currency"
      @set-currency="setCurrency"
    )
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from "vue";
import { getDefaultPreFiltrationFormContract } from "@/utils/getters/defaultFilters";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import { storeToRefs } from "pinia";

import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import CostRange from "@/components/ui/form/input/CostRange.vue"
import CurrencySelector from "@/components/selector/CurrencySelector.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";

import type { PropType } from "vue";
import type { PreFiltrationFormContractI } from "@/utils/getters/defaultFilters";
import useManualsStore from "@/stores/manuals/useManualsStore";

export default defineComponent({
  name: "CostGuaranteeFilter",
  components: {
    UiCheckbox,
    CurrencySelector,
    FilterTemplate,
    CostRange,
  },
  emits: [
    "close",
    "apply",
    "update:modelValue",
  ],
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormContractI>,
      default: getDefaultPreFiltrationFormContract(),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    const manualsStore = useManualsStore()
    const { currencies } = storeToRefs(manualsStore)

    const { modelValue, visible } = toRefs(props)

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormContractI>(modelValue, visible, ['costContractGuarantee', 'costPostContractGuarantee'], emit, 'contracts')

    const selectedCurrency = computed(() => currencies.value.find(x => x.id === filters.value.costContractGuarantee.currency))

    function setCurrency(id: number) {
      filters.value.costContractGuarantee.currency = id;
      filters.value.costPostContractGuarantee.currency = id;
    }

    return {
      filters,
      selectedCurrency,
      setCurrency,
      cleanFilter,
      applyFilters,
      cancel,
    };
  },
});
</script>

<style scoped lang="scss">
.guarantee-filter {
  width: 456px;

  .cost_input {
    justify-content: space-between;

    .base-input {
      margin: 0 !important;
    }
  }

  .cost-wrapper {
    display: flex;
    flex-flow: column;
    gap: 4px;

    :deep(.ui-checkbox) {
      label {
        font-size: 12px;
      }
    }
  }
}
</style>
