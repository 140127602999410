<template lang="pug">
filter-template(
  show-sorting
  :sorting-order="filters.sorting.find(e => e.title === 'status')?.order"
  @save="applyFilters"
  @cancel="cancel"
  @set-order="setOrder"
  @reset="cleanFilter"
)
  template(#header) Статус контракта
  template(#content)
    .contract-statuses(:class="statuses.length > 1 && 'margin'")
      ui-checkbox(as-group v-model="selectAllGetter" id="selectAllContractStatuses" label="Выбрать все" :indeterminate="isIndeterminate && !selectAllGetter")
      .status-filter__content
        .status-block(v-for="(block, index) in statuses" :key="index")
          span(v-if="statuses.length > 1") {{ block.label }}
          checkbox-group(v-model="filters.contractStatusIds" :options="block.options" id="contract-status")
</template>

<script lang="ts">
import { defineComponent, computed, toRefs } from "vue";
import { useRoute } from "vue-router";
import { getDefaultPreFiltrationFormContract } from "@/utils/getters/defaultFilters";
import { setSorting } from "@/utils/filters/sorting";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";

import type { PropType } from "vue";
import type { PreFiltrationFormContractI } from "@/utils/getters/defaultFilters";
import type { ContractStatusListI } from "@/stores/manuals/ManualsInterface";

import CheckboxGroup from "@/components/ui/checkbox/CheckboxGroup.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import useManualsStore from "@/stores/manuals/useManualsStore";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";

export default defineComponent({
  name: "ContractStatusFilter",
  components: {
    UiCheckbox,
    CheckboxGroup,
    FilterTemplate,
  },
  emits: [
    'update:modelValue',
    'close',
    'apply',
  ],
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormContractI>,
      default: getDefaultPreFiltrationFormContract(),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    const route = useRoute();
    const manualsStore = useManualsStore();

    const statuses = computed<ContractStatusListI[]>(() => manualsStore.contractStatusesGetter.filter((e: ContractStatusListI) => route.name as string === e.tab || route.name === 'contracts'));

    const { modelValue, visible } = toRefs(props)

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormContractI>(modelValue, visible, ['contractStatusIds'], emit, 'contracts')

    function setOrder(mode: 'asc'|'desc'|'') {
      filters.value.sorting = setSorting('status', mode, filters.value.sorting);
    }

    const allIds = []
    statuses.value.forEach(e => allIds.push(...e.options.map(i => i.id)))

    const selectAllGetter = computed({
      get: () => allIds.every(id => filters.value?.contractStatusIds?.includes(id)),
      set(value: boolean) {
        filters.value.contractStatusIds = value ? allIds : []
      }
    })

    const isIndeterminate = computed(() => allIds.some(id => filters.value?.contractStatusIds.includes(id)))

    return {
      filters,
      statuses,
      selectAllGetter,
      isIndeterminate,
      cleanFilter,
      applyFilters,
      cancel,
      setOrder,
    };
  },
});
</script>

<style scoped lang="scss">
.status-filter__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 390px;

  span {
    color: var(--secondary-text-color);
    font-size: 12px;
    line-height: 20px;
    margin: 6px 0;
  }
}

.contract-statuses {
  display: flex;
  flex-flow: column;

  &.margin {
    gap: 6px;
  }
}
</style>
