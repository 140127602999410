<template lang="pug">
filter-template.date-filter(
  sort-label="Сортировать (дата заключения) по:"
  :show-sorting="true"
  :sorting-order="filters.sorting.find(e => e.title === 'dateContractSign')?.order"
  @save="applyFilters"
  @cancel="cancel"
  @set-order="setOrder"
)
  template(#content)
    cleanable-title(from-filter-panel @reset="cleanFilterByName('datePlan')") Планируемая дата
    date-filter-card(
      date-type="datePlan"
      :date-value="filters.datePlan"
      @update:date="filters.datePlan = $event.value"
    )
    cleanable-title(from-filter-panel @reset="cleanFilterByName('dateFact')") Фактическая дата
    date-filter-card(
      date-type="dateFact"
      :date-value="filters.dateFact"
      @update:date="filters.dateFact = $event.value"
    )
    cleanable-title(from-filter-panel @reset="cleanFilterByName('dateContractSign')") Дата заключения контракта
    date-filter-card(
      date-type="dateContractSign"
      :date-value="filters.dateContractSign"
      @update:date="filters.dateContractSign = $event.value"
    )
</template>

<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { getDefaultPreFiltrationFormContract } from "@/utils/getters/defaultFilters";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";

import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import DateFilterCard from "@/components/searchForm/elements/DateFilterCard.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";

import type { PropType } from "vue";
import type { PreFiltrationFormContractI } from "@/utils/getters/defaultFilters";
import { setSorting } from "~/utils/filters/sorting";

export default defineComponent({
  name: "DateContractFilter",
  components: {
    FilterTemplate,
    DateFilterCard,
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormContractI>,
      default: getDefaultPreFiltrationFormContract(),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'close',
    'apply',
  ],
  setup(props, { emit }) {

    const { modelValue, visible } = toRefs(props)

    const {
      filters,
      cancel,
      applyFilters,
      cleanFilter,
      cleanFilterByName,
    } = useFiltersComponent<PreFiltrationFormContractI>(modelValue, visible, ['datePlan', 'dateFact', 'dateContractSign'], emit, 'contracts')

    function setOrder(mode: 'asc'|'desc'|'') {
      filters.value.sorting = setSorting('dateContractSign', mode, filters.value.sorting);
    }

    return {
      filters,
      cancel,
      applyFilters,
      cleanFilter,
      setOrder,
      cleanFilterByName,
    };
  },
});
</script>

<style scoped lang="scss">
.date-filter {
  width: 456px;

  :deep(.filter-title__label) {
    font-weight: bold;
    text-transform: unset;
  }
}
</style>
